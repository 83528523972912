const CONTACT_API = {
    // 左侧数据
    getDeviceMap: {
        method: 'post',
        url: '/display/getDeviceMap'
    },
    //获取右侧数据
    getRecordReport: {
        method: 'post',
        url: '/display/getRecordReport'
    },
}
export default CONTACT_API