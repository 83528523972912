import axios from 'axios'
import service from './contactApi'
// service 循环遍历输出不同的请求方法
let instance = axios.create({
    baseURL: 'https://river.1percentsoft.com/admin-service',
    //baseURL: 'http://192.168.1.180:8086/admin-service',
    timeout: 1000
})
const Http = {}; // 包裹请求方法的容器

// 请求格式/参数的统一
for (let key in service) {
    let api = service[key]; // url method
    // async 作用：避免进入回调地狱
    Http[key] = async function(
        params, // 请求参数 get：url，put，post，patch（data），delete：url
        isFormData = false, // 标识是否是form-data请求
        config = {} // 配置参数  详细配置查看https://blog.csdn.net/qq_41866776/article/details/98478632
    ) {
        let newParams = {}

        //  content-type是否是form-data的判断
        if (params && isFormData) {
            newParams = new FormData()
            for (let i in params) {
                newParams.append(i, params[i])
            }
        } else {
            newParams = params
        }
        // 不同请求的判断  // 这里依据项目要求自行更改传参格式
        let response = {}; // 请求的返回值
        if (api.method === 'put' || api.method === 'post' || api.method === 'patch') {
            try {
                response = await instance[api.method](api.url, newParams, config)
            } catch (err) {
                response = err
            }
        } else if (api.method === 'delete' || api.method === 'get') {
            config.params = newParams // 即将与请求一起发送的 URL 参数// 必须是一个无格式对象(plain object)或 URLSearchParams 对象
            try {
                response = await instance[api.method](api.url, config)
            } catch (err) {
                response = err
            }
        }
        return response; // 返回响应值
    }
}

// 拦截器的添加
// 请求拦截器
instance.interceptors.request.use(config => {
        // 发起请求前做些什么
        // Toast.loading({
        //     mask: false,
        //     duration: 0, // 一直存在
        //     forbidClick: true, // 禁止点击
        //     message: '加载中...'
        // })
        console.log('加载中');
        // this.$toasted.show("加载中")
        return config
    }, () => {
        // 请求错误
        console.log('请求错误，请求稍后重试');
        //this.$toasted.show('请求错误，请求稍后重试')
    })
    // 响应拦截器
instance.interceptors.response.use(response => {
    console.log('response', response);
    // 请求成功
    if (response.data == null && response.config.responseType === 'json' && response.request.responseText != null) {
        try {
            // eslint-disable-next-line no-param-reassign
            response.data = JSON.parse(response.request.responseText);
        } catch (e) {
            // ignored
        }
    }
    return response.data
}, () => {
    console.log('请求错误，请求稍后重试');
    //this.$toasted.show("请求错误，请求稍后重试")

})

export default Http